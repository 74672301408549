document.addEventListener('mouseover', (e) => {
  if (e.target.closest('.animate-object')) {
    const group = e.target.closest('.animate-group')

    if (group) {
      group.querySelectorAll('.animate-object').forEach((el) => {
        el.classList.add('is-playing')
      })
    }
  }
})

document.addEventListener('mouseout', (e) => {
  if (e.relatedTarget) {
    if (e.target.closest('.animate-object')) {
      const group = e.target.closest('.animate-group')
      const didLeave = !e.relatedTarget.classList.contains('animate-object')

      if (group && didLeave) {
        group.querySelectorAll('.animate-object').forEach((el) => {
          if (el.classList.contains('is-playing')) {
            el.addEventListener('animationiteration', () => {
              el.classList.remove('is-playing')
            }, { once: true })
          }
        })
      }
    }
  } else {
    document.querySelectorAll('.animate-object.is-playing').forEach((el) => el.classList.remove('is-playing'))
  }
})

document.addEventListener('click', (e) => {
  const trigger = e.target.closest('[data-transition-to]')

  if (trigger) {
    e.preventDefault()

    const targetScene = document.getElementById(trigger.dataset.transitionTo)
    const transition = document.querySelector('.scene-transition')

    transition.classList.add('is-showing')

    transition.addEventListener('animationend', () => {
      document.querySelectorAll('.scene').forEach((el) => el.classList.remove('is-active'))
      document.querySelectorAll('.animate-object').forEach((el) => el.classList.remove('is-playing'))
      targetScene.classList.add('is-active')

      setTimeout(() => {
        transition.classList.remove('is-showing')
        transition.classList.add('is-hiding')

        transition.addEventListener('animationend', () => {
          transition.classList.remove('is-hiding')
        }, { once: true })
      }, 500)
    }, { once: true })
  }
})
